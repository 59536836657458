import { ASAPFulfillmentTime, ParameterSet, } from "..";
import { Asap } from "../../api/orderingHoursWidget";
import { PreorderHours } from "../../api/preorderHours";
export class FulfillmentTimeValidator {
    constructor(params) {
        this.orderingHours = params.orderingHours;
        this.now = params.now;
        this.asapOnlyRestaurant = !params.enableDelayedDelivery;
        this.preorderSettings = params.preorderSettings;
        this.scope = {
            now: this.now,
            parameterSet: ParameterSet.empty(),
            fulfillmentTime: null,
        };
        this.allowOrderingInClosedRestaurant =
            params.allowOrderingInClosedRestaurant;
        this.preorderHours = new PreorderHours({
            preorderSettings: params.preorderSettings,
            scope: this.scope,
        });
    }
    validate(input) {
        let fulfillmentTime;
        if (input === null) {
            fulfillmentTime = new ASAPFulfillmentTime();
        }
        else {
            // NOTICE Today date chosen, but asapOnly means that ASAP is only option for today dates
            const todayEndOfDay = this.orderingHours.endOfDay(this.now);
            if (this.asapOnlyRestaurant &&
                input.type === "OnTime" &&
                (todayEndOfDay !== null
                    ? input.date.isBefore(todayEndOfDay)
                    : input.date.isToday)) {
                fulfillmentTime = new ASAPFulfillmentTime();
            }
            else {
                // NOTICE Just validate user input without any alterations
                fulfillmentTime = input;
            }
        }
        switch (fulfillmentTime.type) {
            case "ASAP": {
                const asap = new Asap({
                    orderingHours: this.orderingHours,
                    scope: this.scope,
                    allowOrderingInClosedRestaurant: this.allowOrderingInClosedRestaurant,
                    enableDelayedDelivery: !this.asapOnlyRestaurant,
                });
                if (asap.asapOptionAvailable(this.preorderSettings)) {
                    return fulfillmentTime;
                }
                else {
                    return null;
                }
            }
            case "OnTime": {
                const date = fulfillmentTime.date;
                if (!this.orderingHours.canOrderAt(date)) {
                    return null;
                }
                if (!this.preorderHours.canOrderAt(date)) {
                    return null;
                }
                return fulfillmentTime;
            }
        }
    }
}
